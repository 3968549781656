export function titleCase(text) {
  return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
}
export function getImage(image, size = undefined) {
  const NO_IMAGE_LINK = "https://t3.ftcdn.net/jpg/04/34/72/82/360_F_434728286_OWQQvAFoXZLdGHlObozsolNeuSxhpr84.jpg";

  if (!image) return NO_IMAGE_LINK;

  const filename = image.split("product_images/")[1];

  switch (size) {
    case 100:
      return `${process.env.VUE_APP_S3_PATH}product_images/${size}x${size}/${filename}`;
    case 150:
      return `${process.env.VUE_APP_S3_PATH}product_images/${size}x${size}/${filename}`;
    case 200:
      return `${process.env.VUE_APP_S3_PATH}product_images/${size}x${size}/${filename}`;
    case 250:
      return `${process.env.VUE_APP_S3_PATH}product_images/${size}x${size}/${filename}`;
    case 500:
      return `${process.env.VUE_APP_S3_PATH}product_images/${size}x${size}/${filename}`;
    default:
      return `${process.env.VUE_APP_S3_PATH}${image}`;
  }
}

export function forceFileDownload(response, fileName) {
  var today = new Date();
  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }
  today = mm + "-" + dd + "-" + yyyy;

  const url = URL.createObjectURL(
    new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName} - ${today}.xlsx`);
  document.body.appendChild(link);
  link.click();
}

export function formatDate(_date, time = true) {
  console.log(time);
  const date = new Date(_date.split("T")[0]);
  const formattedDate = date
    .toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    })
    .replace(/ /g, "-");

  return formattedDate;
  // const monthNames = [
  //   "Jan",
  //   "Feb",
  //   "Mar",
  //   "Apr",
  //   "May",
  //   "Jun",
  //   "Jul",
  //   "Aut",
  //   "Sep",
  //   "Oct",
  //   "Nov",
  //   "Dec",
  // ];

  // let d = new Date(date),
  //   month = "" + monthNames[d.getMonth()],
  //   day = "" + d.getDate(),
  //   year = d.getFullYear(),
  //   hour = d.getHours(),
  //   min = d.getMinutes();

  // let ampm = hour >= 12 ? "PM" : "AM";
  // hour = hour % 12;
  // hour = hour ? hour : 12; // the hour '0' should be '12'
  // min = min < 10 ? "0" + min : min;
  // let strTime = hour + ":" + min + " " + ampm;

  // if (month.length < 2) month = "0" + month;
  // if (day.length < 2) day = "0" + day;

  // if (time) {
  //   return `${day}-${month}-${year} ${strTime}`;
  // } else {
  //   return `${day}-${month}-${year}`;
  // }
}
